import React, { Fragment, useEffect, useState } from 'react';

import { preset } from 'styles';

import AnydayLogo from 'assets/anyday-logo.svg';
import SideBorders from '../components/SideBorders';
import SEO from '../components/SEO';

const BIANCA_ANYDYA_URL = 'https://anyday.com.au/bianca-venue-page';

const Redirect = () => {
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.replace(BIANCA_ANYDYA_URL);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Fragment>
      <SEO title="Home" />

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '30vh',
          paddingBottom: preset.spacing(5),
          paddingLeft: preset.spacing(5),
          paddingRight: preset.spacing(5),
        }}>
        <a
          href={BIANCA_ANYDYA_URL}
          aria-label="Go to Anyday website"
          css={{
            display: 'inline-flex',
            width: 300,
            textAlign: 'center',
            [preset.breakpoints.md]: { width: 360 },
            [preset.breakpoints.lg]: { width: 400 },
          }}>
          <AnydayLogo width="100%" />
        </a>

        <div
          css={{
            fontFamily: 'PalmerLakePrint',
            fontSize: '1.9rem',
            lineHeight: 1,
            textAlign: 'center',
            [preset.breakpoints.sm]: { fontSize: '2rem' },
            [preset.breakpoints.lg]: { fontSize: '2.5rem' },
            [preset.breakpoints.xxl]: { fontSize: '3rem' },
          }}>
          Bianca has moved to our new website.
          <br />
          In {countdown} seconds, you will be automatically redirected to{' '}
          <a href={BIANCA_ANYDYA_URL} aria-label="Go to Anyday website">
            anyday.com.au
          </a>
        </div>
      </div>

      <SideBorders />
    </Fragment>
  );
};

export default Redirect;
